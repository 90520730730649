import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { useTelegram } from './hooks/useTelegram';
import { useEffect } from 'react';
import Resume from './pages/resume/resume';
import Vacancy from './pages/vacancy/vacancy';
import Notification from './pages/notification/notification';

function App() {
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Resume />} />
          <Route path="/vacancy" element={<Vacancy />} />
          <Route path="/notification" element={<Notification />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
