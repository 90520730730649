import React, { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { useTelegram } from '../../hooks/useTelegram';
import Loader from 'react-js-loader';
import Multiselect from 'multiselect-react-dropdown';

const Resume = () => {
  const { tg } = useTelegram();

  const [resumeData, setResumeData] = useState({
    name: '',
    sphere_id: '',
    profession_id: '',
    technology_ids: [],
    region_id: '',
    exp_years: '',
    currency: '',
    amount: '',
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [regions, setRegions] = useState([]);
  const [spheres, setSpheres] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [technologies, setTechnologies] = useState([]);

  const onSendData = useCallback(() => {
    const {
      name,
      sphere_id,
      profession_id,
      technology_ids,
      region_id,
      exp_years,
      currency,
      amount,
    } = resumeData;

    tg.sendData(
      JSON.stringify({
        name,
        sphere_id,
        profession_id,
        technology_ids,
        region_id,
        exp_years: +exp_years,
        salary: {
          currency,
          amount: +amount,
        },
        phone_number: phoneNumber,
      })
    );
  }, [resumeData, phoneNumber, tg]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
      tg.offEvent('mainButtonClicked', onSendData);
    };
  }, [onSendData, tg]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: 'Rezyume tayyor',
      color: '#7d66c0',
      text_color: '#ffffff',
    });
  }, [tg]);

  useEffect(() => {
    if (
      resumeData.name &&
      resumeData.sphere_id &&
      resumeData.profession_id &&
      resumeData.technology_ids.length &&
      resumeData.region_id &&
      resumeData.exp_years &&
      resumeData.currency &&
      resumeData.amount &&
      phoneNumber.length === 13
    ) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [resumeData, phoneNumber, tg]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/regions`);
        if (response.ok) {
          const { data } = await response.json();

          if (data?.regions) {
            setRegions(data.regions);
          }
        } else {
          throw new Error('Failed to fetch regions');
        }
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    })();

    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/spheres`);
        if (response.ok) {
          const { data } = await response.json();

          if (data?.spheres) {
            setSpheres(data.spheres);
          }
        } else {
          throw new Error('Failed to fetch spheres');
        }
      } catch (error) {
        console.error('Error fetching spheres:', error);
      }
    })();
  }, []);

  const handleChange = async (event) => {
    const name = event.target.name;
    let value = event.target.value;

    setResumeData({
      ...resumeData,
      [name]: value,
    });

    if (name === 'sphere_id') {
      try {
        const prof_res = await fetch(
          `${process.env.REACT_APP_URL}/spheres/${value}/professions`
        );
        if (prof_res.ok) {
          const { data } = await prof_res.json();

          if (data?.professions) {
            setProfessions(data.professions);
          }
        } else {
          throw new Error('Failed to fetch professions');
        }

        const categ_res = await fetch(
          `${process.env.REACT_APP_URL}/categories?sphere_id=${value}`
        );
        if (categ_res.ok) {
          const { data } = await categ_res.json();

          if (data?.categories) {
            let result = [];

            for (const category of data.categories) {
              if (category.technologies?.length) {
                result = result.concat(
                  category.technologies.map((technology) => ({
                    _id: technology._id,
                    name: technology.name,
                    category: category.translations.uz.name,
                  }))
                );
              }
            }

            setTechnologies(result);
          }
        } else {
          throw new Error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching professions:', error);
      }
    }
  };

  return regions.length && spheres.length ? (
    <div className="form_wrap">
      <h1 className="form_title">
        Here <b>Jobs</b>
      </h1>

      <div className="form_form">
        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Ism:</label>
          <input
            placeholder="Ismingizni kiriting"
            type="text"
            name="name"
            value={resumeData.name}
            onChange={handleChange}
          />
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Kasb sohasi:
          </label>
          <select
            name="sphere_id"
            value={resumeData.sphere_id}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Kasb sohasini tanlang
            </option>
            {spheres.map((sphere) => (
              <option key={sphere._id} value={sphere._id}>
                {sphere.translations.en.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Kasb:</label>
          <select
            name="profession_id"
            value={resumeData.profession_id}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Kasbni tanlang
            </option>
            {professions.map((profession) => (
              <option key={profession._id} value={profession._id}>
                {profession.translations.en.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Texnologiyalar:
          </label>
          <Multiselect
            options={technologies}
            onSelect={(e) => {
              resumeData.technology_ids = e.map((el) => el._id);
            }}
            onRemove={(e) => {
              resumeData.technology_ids = e.map((el) => el._id);
            }}
            displayValue="name"
            placeholder="Texnologiyalarni tanlang"
            selectionLimit={15}
            avoidHighlightFirstOption={true}
            groupBy="category"
          />
        </div>

        <div>
          <label
            style={{ display: 'flex', marginBottom: '2%', marginTop: '3%' }}
          >
            Viloyat:
          </label>
          <select
            name="region_id"
            value={resumeData.region_id}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Viloyatni tanlang
            </option>
            {regions.map((region) => (
              <option key={region._id} value={region._id}>
                {region?.translations.uz?.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Tajriba:
          </label>
          <input
            placeholder="Tajriba yilini kiriting"
            type="number"
            name="exp_years"
            min="0"
            value={resumeData.exp_years}
            onChange={handleChange}
          />
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Maosh valyutasi:
          </label>
          <select
            name="currency"
            value={resumeData.currency}
            onChange={handleChange}
          >
            <option value="" disabled hidden>
              Valyutani tanlang
            </option>
            <option key="0" value="usd">
              USD
            </option>
            <option key="1" value="soum">
              So'm
            </option>
          </select>
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>Maosh:</label>
          <input
            placeholder="Maoshni kiriting"
            type="number"
            name="amount"
            value={resumeData.amount}
            min="0"
            onChange={handleChange}
          />
        </div>

        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Telfon raqam:
          </label>
          <PhoneInput
            countries={['UZ']}
            country="UZ"
            international={true}
            countryCallingCodeEditable={false}
            defaultCountry="UZ"
            value={phoneNumber}
            onChange={setPhoneNumber}
            maxLength={17}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="loader-wrapper">
      <div className={'zoom-out'}>
        <div className={'row'}>
          <div className={'item'}>
            <Loader type="spinner-default" size={100} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
