const tg = window.Telegram.WebApp;

export function useTelegram() {
  tg.expand();
  tg.enableClosingConfirmation();

  const onToggleButton = () => {
    if (tg.MainButton.isVisible) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  };

  return { onToggleButton, tg };
}
