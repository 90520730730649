import React, { useEffect, useState } from 'react';
import { useTelegram } from '../../hooks/useTelegram';
import Loader from 'react-js-loader';
import Multiselect from 'multiselect-react-dropdown';

const Notification = () => {
  const { tg } = useTelegram();
  const [spheres, setSpheres] = useState([]);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const categ_res = await fetch(`${process.env.REACT_APP_URL}/spheres`);

        if (categ_res.ok) {
          const { data } = await categ_res.json();

          if (data?.spheres) {
            let result = [];

            for (const sphere of data.spheres) {
              result = result.concat({
                _id: sphere._id,
                name: sphere.translations.en.name,
              });
            }

            setSpheres(result);
          }
        } else {
          throw new Error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching professions:', error);
      }
    })();
  }, []);

  return spheres.length ? (
    <div
      className="form_wrap"
      onSubmit={(e) => {
        console.log(e);
      }}
    >
      <h1 className="form_title">
        Here <b>Jobs</b>
      </h1>

      <div className="form_form">
        <div>
          <label style={{ display: 'flex', marginBottom: '2%' }}>
            Kasb yo'nalishlari:
          </label>
          <Multiselect
            options={spheres}
            onSelect={(e) => {
              setNotifications(e.map((el) => el._id));
            }}
            onRemove={(e) => {
              setNotifications(e.map((el) => el._id));
            }}
            displayValue="name"
            placeholder="Kasb sohalarini tanlang"
            avoidHighlightFirstOption={true}
          />
        </div>
        <button type="submit" onSubmit={(e) => console.log(e)}>
          Submit
        </button>
      </div>
    </div>
  ) : (
    <div className="loader-wrapper">
      <div className={'zoom-out'}>
        <div className={'row'}>
          <div className={'item'}>
            <Loader type="spinner-default" size={100} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
